import React from "react";
import {
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Chip
} from "@material-ui/core";
import useStyles from "./styles";

const states = {
  succeed: "success",
  errored: "warning",
  failed: "secondary",
};

const KEY_ARRAY= ['Name', 'Status', 'Status Text', 'Created At', 'Event Name'];

export default function TableComponent({ onStarClick, data }) {

  const classes = useStyles();
  if(!Array.isArray(data) || !data.length || !data[0]) {
    return (null);
  }

  return (
    <TableContainer>
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          {KEY_ARRAY.map(key => (
            <TableCell style={{
            }} key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
          {data.map(({ id, fullName, processedDate, status, statusText, eventName }) => (
          <TableRow key={id}>
            <TableCell className="pl-3 fw-normal">{fullName}</TableCell>
            <TableCell>
              <Chip label={status} classes={{root: classes[states[status.toLowerCase()]]}}/>
            </TableCell>
            <TableCell>{statusText}</TableCell>
            <TableCell>{processedDate}</TableCell>
            <TableCell>{eventName}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
  );
}
