import axios from 'axios';
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import classNames from "classnames";
import {
  AppBar,
  Button,
  Toolbar,
  TextField,
  Menu,
  MenuItem,
  IconButton,
  LinearProgress
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  ArrowBack as ArrowBackIcon,
  Menu as MenuIcon,
  Person as AccountIcon,
  LockOpen,
} from "@material-ui/icons";
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { useAuth0 } from "@auth0/auth0-react";
import {
  AUTH0_LOGOUT_REDIRECT_URI,
  PROFILE
} from '../../utils/constants';

import {
  formatChangePsRequestOption
} from '../../utils/formatter';

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";
import { fetchCreateOrGetUser } from '../../reducerCollection/AuthOptions';
import { toggleSidebar } from '../../reducerCollection/ThemeOptions';

function _validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function Header(props) {
  var classes = useStyles();
  const { firstname, accessToken, isSidebarOpened } = props;

  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const [profileMenu, setProfileMenu] = useState(null);

  //====================
  //password
  //====================
  const [psOpen, setPsOpen] = React.useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [isSubmittedShowed, setIsSubmittedShowed] = useState(false);
  const [submitError, setSubmitError] = React.useState(false);


  const handleClickOpen = () => {
    setPsOpen(true);
  };

  const handlePsClose = () => {
    setPsOpen(false);
    setEmail('');
    setEmailError(false);
    setEmailLoading(false);
  };

  const onEmailChange = (event) => {
    setEmailError(false);
    setEmail(event.target.value.trim());
  }

  const onSubmitClose = () => {
    setIsSubmittedShowed(false);
    setSubmitError(false);
  }

  const handlePsSubmit = () => {
    if(email === '' || !_validateEmail(email)) {
      //Display error
      setEmailError(true);
    } else {
      setEmailLoading(true);
      axios.request(formatChangePsRequestOption(email)
        ).then(function (response) {
          setEmailLoading(false);
          setPsOpen(false);
          setIsSubmittedShowed(true);

          setSubmitError(false);
          console.log(response.data);
        }).catch(function (error) {
          setEmailLoading(false);
          setPsOpen(false);
          setIsSubmittedShowed(true);

          setSubmitError(true);
          console.error(error);
        });
      }
  }

  useEffect(() => {
    accessToken && dispatch(fetchCreateOrGetUser());
  }, [accessToken]);

  return (
    <div>
      <Dialog
        open={isSubmittedShowed}
        onClose={onSubmitClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { submitError ?
              'Something went wrong, please submit again' :
              'Request sent, please check your email' }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmitClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={psOpen} onClose={handlePsClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To reset your password, please enter your email. We will send you an instruction.
          </DialogContentText>
          { emailLoading ? <LinearProgress /> :
            <TextField
              autoFocus
              error={emailError}
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              onChange={onEmailChange}
            />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePsClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePsSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => props.toggleSidebar()}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          RSVPHub
        </Typography>

        <div className={classes.grow} />
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountCircleOutlinedIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              { firstname ? `Hi ${firstname}` : 'Hi there' }
            </Typography>
          </div>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
            component={Link}
            to={PROFILE}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Profile
          </MenuItem>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
            onClick={handleClickOpen}
          >
            <LockOpen className={classes.profileMenuIcon} /> Change Password
          </MenuItem>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => { logout({ returnTo: AUTH0_LOGOUT_REDIRECT_URI }); }}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>

      </Toolbar>
    </AppBar>
    </div>
  );
}

const mapStateToProps = state => ({
  firstname: state.AuthOptions.firstname,
  accessToken: state.AuthOptions.accessToken,
  isSidebarOpened: state.ThemeOptions.isSidebarOpened
});

const mapDispatchToProps = dispatch => ({
  toggleSidebar: ()  => dispatch(toggleSidebar()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
