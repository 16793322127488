const API_DOMAIN = 'https://nwl2ecbku7.execute-api.us-east-1.amazonaws.com/prod';

export const formatAlbumItemURL = (id) => {
  return `${API_DOMAIN}/albumitem/${id}`
}

export const formatAlbumItemsURL = () => {
  return `${API_DOMAIN}/albumitems`
}

export const formatAlbumS3SignedURL = (uploadFileName) => {
  return `${API_DOMAIN}/albums3url/${uploadFileName}`
}

export const formatCheckinURL = (checkinId) => {
  return `${API_DOMAIN}/checkin/${checkinId}`
}

export const formatCheckins = () => {
  return `${API_DOMAIN}/checkins`
}

export const formatCheckinS3SignedURL = (uploadFileName) => {
  return `${API_DOMAIN}/checkins3url/${uploadFileName}`
}


export const formatGetUserURL = () => {
  return `${API_DOMAIN}/user`;
}

export const formatCreateUserURL = () => {
  return `${API_DOMAIN}/user`;
}

export const formatUpdateUserURL = () => {
  return `${API_DOMAIN}/user`;
}

export const formatUpdateSupportURL = () => {
  return `${API_DOMAIN}/support`;
}

