//DOMAIN
export const LOCAL_HOST = "http://localhost:3000/"; // Used for dev
export const DOMAIN = "https://www.rsvphub.co/" // Use for marketing page, log out redirect
export const SUB_DOMAIN = "https://app.rsvphub.co/" // Use for real app, log in redirect

// Auth0
export const AUTH0_CLIENT_ID = 'C0sARgNzhA5CdYsaLegrEcCBvIITw0ei';
export const AUTH0_CLIENT_SECRET = 'qS-qCsfAVOiTLPjqlY96z8Vi-n32nW2cZ_wXb8qWB2in_Mj5VLrsmRZnDZMuwBBu';
export const AUTH0_DOMAIN = 'dev-0guruhpg.us.auth0.com';
export const AUTH0_AUDIENCE = `https://your-api-gateway/`;
export const AUTH0_LOGIN_REDIRECT_URI = `${SUB_DOMAIN}`; // this needs to match auth0.com
export const AUTH0_LOGOUT_REDIRECT_URI = `${DOMAIN}`; // this needs to match auth0.com
export const RESET_PS_URI = `https://${AUTH0_DOMAIN}/dbconnections/change_password`;

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

// Routes
export const INTRO = '/';
export const DASHBOARD = '/dashboard';
export const CHECK_IN_DASH = '/checkindash';
export const CHECKIN = '/checkin';
export const INDEX = '/index';
export const ALBUM = '/album';

export const USAGE = '/usage';
export const EVENT1 = '/event1';
export const EVENT2 = '/evnet2';
export const UPGRADE = '/upgrade';
export const SUPPORT = '/support';
export const PROFILE = '/profile';
export const ERROR_500 = '/error500';
export const CATCH_ALL = '/*';
