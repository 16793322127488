import React, { useState } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Paper
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// API
import { formatUpdateUserURL } from '../../utils/apis';
import { formatRequestHeader } from '../../utils/formatter';


function ProfilePage(props) {
  const {
    accessTokenState,
    firstname,
    lastname,
    contactnumber,
    address,
    role,
  } = props;


  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [firstName, setFirstName] = useState(firstname);
  const [lastName, setLastName] = useState(lastname);
  const [contactNumber, setContactNumber] = useState(contactnumber);
  const [roleState, setRoleState] = useState(role);
  const [addressState, setAddressState] = useState(address);

  const handleSubmit = async () => {
    setIsDialogOpen(true);
    await axios.patch(formatUpdateUserURL(),
      {
        'firstname': firstName,
        'lastname': lastName,
        'contactnumber': contactNumber,
        'role': roleState,
        'address': addressState
      },
      {
        headers: formatRequestHeader(accessTokenState)
      }
      ).then(resp => {
        //console.log(resp);

    }).catch(error => {
        console.error(error);
    });

  }


  const onFirstNameChange = (event) => {
    setFirstName(event.target.value.trim());
  }

  const onLastNameChange = (event) => {
    setLastName(event.target.value.trim());
  }

  const onContactNumberChange = (event) => {
    setContactNumber(event.target.value.trim());
  }

  const onRoleChange = (event) => {
    setRoleState(event.target.value.trim());
  }

  const onAddressChange = (event) => {
    setAddressState(event.target.value.trim());
  }

  const dialogClose = () => {
    setIsDialogOpen(false);
  }

  return (
    <>
    <Dialog
        open={isDialogOpen}
        onClose={dialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Request Submitted"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Request submitted, please check back later
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

    <Paper spacing={4} style={{minHeight: '100vh',minWidth: '50vw'}}>
      <Grid container spacing={6}>
          <Grid item xs={3} md={4}>
            <Paper />
          </Grid>
          <Grid item xs={7} md={4}>
            <Box mr={4} mt={4}>
               <Typography variant="h2" gutterBottom>
                  Edit User Info
                </Typography>
            </Box>
          </Grid>
          <Grid item xs={2} md={4}>
            <Paper />
          </Grid>
        </Grid>

      <Grid container spacing={6}>
          <Grid item xs={3} md={4}>
            <Paper />
          </Grid>
          <Grid item xs={7} md={4}>
            <Box mr={4} mt={4}>
              <TextField
                variant="outlined"
                id="First-name"
                label="First Name"
                value={firstName}
                onChange={onFirstNameChange}
                fullWidth={true}
                helperText="Enter your first name"
              />
            </Box>
          </Grid>
          <Grid item xs={2} md={4}>
            <Paper />
          </Grid>
        </Grid>

      <Grid container spacing={6}>
          <Grid item xs={3} md={4}>
            <Paper />
          </Grid>
          <Grid item xs={7} md={4}>
            <Box mr={4} mt={4}>
              <TextField
                variant="outlined"
                id="Last-name"
                label="Last Name"
                value={lastName}
                onChange={onLastNameChange}
                fullWidth={true}
                helperText="Enter your Last name"
              />
            </Box>
          </Grid>
          <Grid item xs={2} md={4}>
            <Paper />
          </Grid>
        </Grid>

      <Grid container spacing={6}>
          <Grid item xs={3} md={4}>
            <Paper />
          </Grid>
          <Grid item xs={7} md={4}>
            <Box mr={4} mt={4}>
              <TextField
                variant="outlined"
                id="contact-number"
                label="Contact Number"
                value={contactNumber}
                onChange={onContactNumberChange}
                fullWidth={true}
                helperText="Enter your contact number"
              />
            </Box>
          </Grid>
          <Grid item xs={2} md={4}>
            <Paper />
          </Grid>
        </Grid>

      <Grid container spacing={6}>
          <Grid item xs={3} md={4}>
            <Paper />
          </Grid>
          <Grid item xs={7} md={4}>
            <Box mr={4} mt={4}>
               <TextField
                  id="outlined-select-role"
                  select
                  label="Role"
                  value={roleState}
                  onChange={onRoleChange}
                  helperText="Select your role"
                  variant="outlined"
                  fullWidth={true}
                >
                  <MenuItem value='admin'>Admin</MenuItem>
                  <MenuItem value='user'>User</MenuItem>
                </TextField>
            </Box>
          </Grid>
          <Grid item xs={2} md={4}>
            <Paper />
          </Grid>
        </Grid>


      <Grid container spacing={6}>
          <Grid item xs={3} md={4}>
            <Paper />
          </Grid>
          <Grid item xs={7} md={4}>
            <Box mr={4} mt={4}>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={6}
                fullWidth={true}
                placeholder="Address"
                variant="outlined"
                value={addressState}
                onChange={onAddressChange}
                helperText="Enter your address"
              />
            </Box>
          </Grid>
          <Grid item xs={2} md={4}>
            <Paper />
          </Grid>
        </Grid>

      <Grid container spacing={6}>
              <Grid item xs={3} md={4}>
                <Paper />
              </Grid>
              <Grid item xs={7} md={4}>
                <Box mr={4} mt={4}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={2} md={4}>
                <Paper />
              </Grid>
            </Grid>
    </Paper>
    </>
  );
}


const mapStateToProps = state => ({
  accessTokenState: state.AuthOptions.accessToken,
  firstname: state.AuthOptions.firstname,
  lastname: state.AuthOptions.lastname,
  contactnumber: state.AuthOptions.contactnumber,
  address: state.AuthOptions.address,
  role: state.AuthOptions.role,
});

export default connect(mapStateToProps)(ProfilePage);