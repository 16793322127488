import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Box, Grid, Paper } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";


function Dashboard(props) {
  var classes = useStyles();

  return (
    <>

      <Paper spacing={4} style={{minHeight: '100vh',minWidth: '50vw'}}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
      >
        <Grid container>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={10} md={6}>
            <Box m={5}>
              <Widget title="How To" disableWidgetMenu>
                <div className={classes.dashedBorder}>
                  <Typography className={classes.text} size="md">
                    1. Index faces with names under Index tab first
                  </Typography>
                  <Typography className={classes.text} size="md">
                    2. Click 'CheckIn' button below to start checkin process
                  </Typography>
                </div>
              </Widget>
            </Box>
          </Grid>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={3} md={5}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={6} md={2}>
            <Box m={5}>
              <Button
                component={Link}
                to={'/checkin'}
                variant={"contained"}
                color="secondary">
                  Start CheckIn
              </Button>
            </Box>
          </Grid>
          <Grid item xs={3} md={5}>
            <Paper></Paper>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
    </>
  );
}


const mapStateToProps = state => ({
  accessTokenState: state.AuthOptions.accessToken,
});

export default connect(mapStateToProps)(Dashboard);