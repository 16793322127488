import React, { useState } from "react";
import { connect } from 'react-redux';
import { Button, Grid, Paper } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import axios from 'axios';


import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";

// API
import {
  formatUpdateSupportURL
} from '../../utils/apis';

import { formatRequestHeader } from '../../utils/formatter';

// styles
import useStyles from "./styles";


function SupportPage(props) {
  const classes = useStyles();

  const { accessTokenState } = props;
  const [text, setText] = useState('');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const textOnChange = (event) => {
    setText(event.target.value);
  };

  const buttonOnClick = async () => {
    handleClickOpen();
    await axios.patch(formatUpdateSupportURL(),
      {
        supportnote: text
      },
      {
        headers: formatRequestHeader(accessTokenState)
      }
      ).then(resp => {
        //console.log(resp);

    }).catch(error => {
        console.error(error);
    });
  }

  return (
    <div>
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Request Submitted"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Request submitted, the team has been notified, we will contact you shortly.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

    <Paper spacing={4} style={{minHeight: '100vh',minWidth: '50vw'}}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
      >
        <Grid container spacing={4}>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={10} md={6}>
            <Box m={4}>
              <Widget disableWidgetMenu>
                <div className={classes.dashedBorder}>
                  <Typography className={classes.text} size="md">
                    We support over 25 languages.
                  </Typography>
                  <Typography className={classes.text} size="md">
                    Please type your question down below, we will send you an email with answers.
                  </Typography>
                </div>
              </Widget>
            </Box>
          </Grid>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={10} md={6}>
            <Box m={5}>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={20}
                fullWidth={true}
                placeholder="Start typing here"
                variant="outlined"
                onChange={textOnChange}
              />
            </Box>
          </Grid>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box m={5}>
              <Button
              fullWidth={true}
              size='large'
              color="secondary"
              variant="contained"
              onClick={buttonOnClick}
              >
                  Submit
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper></Paper>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
    </div>
  );
}


const mapStateToProps = state => ({
  accessTokenState: state.AuthOptions.accessToken,
});

export default connect(mapStateToProps)(SupportPage);