import React, { Suspense } from 'react';
import { Route, Switch } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import Layout from "./Layout";
import ProtectedRoute from '../components/ProtectedRoute';

// pages
import IntroPage from "../pages/IntroPage";
import Dashboard from "../pages/dashboard";
import Collection from "../pages/collection";
import Error from "../pages/error";
import Dash from "../pages/dash";
import Checkin from "../pages/checkin";
import Index from "../pages/index";
import ProfilePage from "../pages/ProfilePage";
import SupportPage from "../pages/SupportPage";
import UpgradePage from "../pages/UpgradePage";
import EventPage from "../pages/EventPage";
import UsagePage from "../pages/UsagePage";


// Routes
import {
  INTRO,
  DASHBOARD,
  CHECK_IN_DASH,
  CHECKIN,
  INDEX,
  ALBUM,
  USAGE,
  EVENT1,
  EVENT2,
  UPGRADE,
  SUPPORT,
  PROFILE,
  ERROR_500,
  CATCH_ALL
} from '../utils/constants';


const HeaderSideBarElement = (WrappedComponent) => (props) => {
  return (
    <Layout>
      <WrappedComponent {...props}/>
    </Layout>
  )
}

export default function App() {
  return (
    <AnimatePresence>
      <Suspense>
        <Switch>
          <ProtectedRoute exact path={INTRO} component={HeaderSideBarElement(IntroPage)} />
          <ProtectedRoute exact path={DASHBOARD} component={HeaderSideBarElement(Dashboard)} />
          <ProtectedRoute exact path={CHECK_IN_DASH} component={HeaderSideBarElement(Dash)} />
          <ProtectedRoute exact path={CHECKIN} component={HeaderSideBarElement(Checkin)} />
          <ProtectedRoute exact path={INDEX} component={HeaderSideBarElement(Index)} />
          <ProtectedRoute exact path={ALBUM} component={HeaderSideBarElement(Collection)} />

          <ProtectedRoute exact path={PROFILE} component={HeaderSideBarElement(ProfilePage)} />
          <ProtectedRoute exact path={SUPPORT} component={HeaderSideBarElement(SupportPage)} />
          <ProtectedRoute exact path={UPGRADE} component={HeaderSideBarElement(UpgradePage)} />
          <ProtectedRoute exact path={EVENT1} component={HeaderSideBarElement(EventPage)} />
          <ProtectedRoute exact path={EVENT2} component={HeaderSideBarElement(EventPage)} />
          <ProtectedRoute exact path={USAGE} component={HeaderSideBarElement(UsagePage)} />


          <Route path={ERROR_500} component={Error} />
          <Route path={CATCH_ALL} component={Error} />
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
}
