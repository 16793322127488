import React, { useState } from "react";
import Webcam from "react-webcam";
import {
  Button,
  Box,
  CircularProgress,
  Grid,
  Paper } from "@material-ui/core";


const WebcamCapture = (props) => {
  const webcamRef = React.useRef(null);
  const { height, width, inProgress, buttonClick , buttonText } = props;

  const videoConstraints = {
  width: width,
  height: height,
  facingMode: "user"
};


  const onButtonClick = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      buttonClick(imageSrc);
    },
    [webcamRef, buttonClick]
  );

  return (
    <>
     <Grid container>
          <Grid item xs={2} md={2}>
            <Paper />
          </Grid>
          <Grid item xs={9} md={9}>
              <Webcam
              width={width}
              height={height}
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
          </Grid>
          <Grid item xs={1} md={1}>
            <Paper/>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={1} md={5}>
            <Paper />
          </Grid>
          <Grid item xs={5} md={2}>
            <Box m={4}>
              <Button
                variant={"contained"}
                color="secondary"
                onClick={onButtonClick}
                disabled={inProgress}>
                {buttonText}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={5} md={2}>
            <Box m={4}>
              {
                inProgress ?
                <CircularProgress color="inherit" /> :
                <Paper />
              }
            </Box>
          </Grid>
          <Grid item xs={1} md={3}>
            <Paper/>
          </Grid>
        </Grid>
    </>
  );
};

export default WebcamCapture;
