import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Chip
} from "@material-ui/core";
import useStyles from "../../styles";

const states = {
  succeed: "success",
  errored: "warning",
  failed: "secondary",
};

const KEY_ARRAY= ['Name', 'Status', 'Status Text', 'Created At'];

export default function TableComponent({ data }) {
  const classes = useStyles();
  if(!Array.isArray(data) || !data.length || !data[0]) {
    return (null);
  }

  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          {KEY_ARRAY.map(key => (
            <TableCell key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(({ id, fullname, processedDate, status, statusText }) => (
          <TableRow key={id}>
            <TableCell className="pl-3 fw-normal">{fullname}</TableCell>
            <TableCell>
              <Chip label={status} classes={{root: classes[states[status.toLowerCase()]]}}/>
            </TableCell>
            <TableCell>{statusText}</TableCell>
            <TableCell>{processedDate}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
