import React, { useState, useEffect } from "react";
import { Circle } from 'rc-progress';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Button,
  Box,
  Grid,
  LinearProgress,
  Paper,
  Container
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { Link } from "react-router-dom";
// components
import { Typography } from "../../components/Wrappers/Wrappers";
import Widget from "../../components/Widget/Widget";
// API
import { formatCheckins } from '../../utils/apis';
// Route
import {
  UPGRADE,
} from '../../utils/constants';
// Formatter
import {
  formatRequestHeader
} from '../../utils/formatter';

function UsagePage(props) {
  const {
    accessTokenState,
    eventlimit,
    attendeeslimit,
    plan
  } = props;
  var theme = useTheme();
  const [eventPercentage, setEventPercentage] = useState(1);
  const [attendeePercentage, setAttendeePrecentage] = useState(1);
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    const event1Promise = accessTokenState && axios.get(formatCheckins(), {
      headers: formatRequestHeader(accessTokenState),
      params: {
        eventName: 'event1' // event1 vs event2, hard code for now
      }
    });

    const event2Promise = accessTokenState && axios.get(formatCheckins(), {
      headers: formatRequestHeader(accessTokenState),
      params: {
        eventName: 'event2' // event1 vs event2, hard code for now
      }
    });

    Promise.all([event1Promise, event2Promise]).then(function(values) {

      const event1Atten = values[0]['data']['count'];
      const event2Atten = values[1]['data']['count'];
      const event1Count = event1Atten !== 0 ? 1 : 0;
      const event2Count = event2Atten !== 0 ? 1 : 0;

      setAttendeePrecentage(((event1Atten+event2Atten)/attendeeslimit).toFixed(2)*100);
      setEventPercentage(((event1Count+event2Count)/eventlimit).toFixed(2)*100);
      setIsPageLoading(false);
    }).catch(error => {
      console.error(error);
      setIsPageLoading(false);
    });

  },[accessTokenState, attendeeslimit, eventlimit, plan]);

  if(isPageLoading) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
    <Paper spacing={4} style={{minHeight: '100vh',minWidth: '50vw'}}>
      <Grid container spacing={4}>
          <Grid container spacing={4}>
            <Grid container spacing={4}>
              <Grid item xs={1} md={3}>
                <Paper></Paper>
              </Grid>
              <Grid item xs={10} md={6}>
                <Box m={4}>
                  <Widget title="Instruction" disableWidgetMenu>
                    <div >
                      <Typography variant="h6">
                        Current Plan Usage
                      </Typography>
                    </div>
                  </Widget>
                </Box>
              </Grid>
              <Grid item xs={1} md={3}>
                <Paper></Paper>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={1} md={3}>
                <Paper />
              </Grid>
              <Grid item xs={5} md={3}>
                <Box m={4}>
                  <Typography variant="h3">
                  Attendee Usage {attendeePercentage}%
                </Typography>
                </Box>
              </Grid>
              <Grid item xs={5} md={3}>
                <Box m={4}>
                  <Typography variant="h3">
                    Event Usage {eventPercentage}%
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1} md={3}>
                <Paper/>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={1} md={3}>
                <Paper></Paper>
              </Grid>
              <Grid item xs={5} md={3}>
                <Container maxWidth="sm">
                  <Circle percent={attendeePercentage} strokeWidth="4" strokeColor={theme.palette.success.main} />
                </Container>
              </Grid>
              <Grid item xs={5} md={3}>
                <Container maxWidth="sm">
                  <Circle percent={eventPercentage} strokeWidth="4" strokeColor={theme.palette.warning.main} />
                </Container>
              </Grid>
              <Grid item xs={1} md={3}>
                <Paper></Paper>
              </Grid>
            </Grid>

            <Grid container spacing={6}>
              <Grid item xs={3} md={5}>
                <Paper />
              </Grid>
              <Grid item xs={6} md={4}>
                <Box mt={4}>
                  <Button
                    disabled={plan === 'pro'}
                    component={Link}
                    variant="contained"
                    color="primary"
                    to={UPGRADE}
                  >
                    Request Upgrade Plan
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={3} md={2}>
                <Paper />
              </Grid>
            </Grid>
          </Grid>
      </Grid>
    </Paper>
    </>
  );
}

const mapStateToProps = state => ({
  accessTokenState: state.AuthOptions.accessToken,
  eventlimit: state.AuthOptions.eventlimit,
  attendeeslimit: state.AuthOptions.attendeeslimit,
  plan: state.AuthOptions.plan,
});

export default connect(mapStateToProps)(UsagePage);