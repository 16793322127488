import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Button,
  Box,
  TextField,
  Grid,
  Paper,
  LinearProgress } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


// styles
import useStyles from "./styles";


// components
import Camera from '../../components/Cam/Cam';
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";

// API
import {
  formatAlbumItemsURL,
  formatAlbumS3SignedURL
} from '../../utils/apis';


const SUBMIT_WORDING = 'Request submitted, please wait 15 seconds for the result';
const ERROR_WORDING = 'Something went wrong, please try again later';
const SUCCESS_WORDING = 'Index successful, please check details in Album page';

function CheckInPage({accessTokenState}) {
  const [dialogWording, setDialogWording] = React.useState(SUBMIT_WORDING);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const [inProgress, setInProgress] = React.useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [hasNameError, setHasNameError] = useState(false);
  const [name, setName] = useState('');
  const [height, setHeight] = useState(document.documentElement.clientHeight * 0.8);
  const [width, setWidth] = useState(document.documentElement.clientWidth * 0.6);

  var classes = useStyles();

  const indexClick = (imageSrc) => {
    setImgSrc(imageSrc);
  }

  /**
   * Fire once per 3 seconds, 3 times total
   * check if indexed use fileName + status
   * Trigger error or success msg
   */
  const checkIsIndexed = (fileName) => {
    let isIndexed = false;
    let isErrored = false;
    let counter = 0;
    var intervalId = window.setInterval(function(){
      counter++;
      if(isErrored === true) {
        // Stop loop
        clearInterval(intervalId);
        // Set error state
        setDialogWording(ERROR_WORDING);
        setIsDialogOpen(true);
        setInProgress(false);
        return;
      }
      if(isIndexed === true) {
        // Stop loop
        clearInterval(intervalId);
        // Set success state
        setDialogWording(SUCCESS_WORDING);
        setIsDialogOpen(true);
        setInProgress(false);
        return;
      }
      if(counter === 3) {
        // time out, we don't show anything
        clearInterval(intervalId);
        setInProgress(false);
        return;
      }
      /// call your function here
      axios.get(
      formatAlbumItemsURL(),
        {
          headers: {
            'Cache-Control' : 'no-cache',
            'Content-Type': 'image/jpeg',
            'Access-Control-Allow-Origin': '*',
            'X-Api-Key': '*',
            'Access-Control-Allow-Credentials' : true, // Required for cookies, authorization headers with HTTPS
            Authorization: `Bearer ${accessTokenState}`
          }
        }).then(response => {
          const dbItems = response.data.items;
          dbItems.forEach(function (item, index) {
            if(item['s3InputKey'] === fileName && item['status'] === 'SUCCEED') {
                isIndexed = true;
            }

            if(item['s3InputKey'] === fileName && item['status'] === 'ERRORED') {
                isErrored = true;
            }
          })

        }).catch(e => {
          // Stop loop
          clearInterval(intervalId);
          // Set error state
          setDialogWording(ERROR_WORDING);
          setIsDialogOpen(true);
          setInProgress(false);

          console.error(e);
        });
      }, 3000);
  }


  /**
   *
   * Upload fullname + photo to s3
   * Then check if indexed use fileName + status
   */
  const triggerUpload = () => {
    if(name === '') {
      setHasNameError(true);
      return;
    }

    // trigger loading
    setDialogWording(SUBMIT_WORDING);
    setIsDialogOpen(true);

    setInProgress(true);
    const fileKey = uuidv4();
    const fileName = `${fileKey}.jpeg`;


    axios.get(
      formatAlbumS3SignedURL(fileName),
    {
      headers: {
        'Cache-Control' : 'no-cache',
        'Content-Type': 'image/jpeg',
        'Access-Control-Allow-Origin': '*',
        'X-Api-Key': '*',
        'Access-Control-Allow-Credentials' : true, // Required for cookies, authorization headers with HTTPS
        Authorization: `Bearer ${accessTokenState}`,
        fullName: name
      }
    }).then(response => {
      const file = Buffer.from(imgSrc.replace(/^data:image\/\w+;base64,/, ""),'base64')
      axios({
        method: 'PUT',
        url: response.data.signedUrl,
        data: file
      },
      {
        headers: {
          'Cache-Control' : 'no-cache',
          'Content-Type': 'image/jpeg',
          'Access-Control-Allow-Origin': '*',
          'X-Api-Key': '*',
          'Access-Control-Allow-Credentials' : true, // Required for cookies, authorization headers with HTTPS
          Authorization: `Bearer ${accessTokenState}`,
          fullName: name
        }
      }).then(result => {
        setName('');
        setHasNameError(false);

        checkIsIndexed(fileName);
      });
    }).catch(e => {
      setInProgress(false);

      setDialogWording(ERROR_WORDING);
      setIsDialogOpen(true);

      console.error(e);
    });
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  }

  const onTextFieldChange = (event) => {
    const name = event.target.value.trim().split(/\r?\n/);
    setName(name);
    setHasNameError(false);
  }


  useEffect(() => {
    imgSrc && triggerUpload();
  }, [imgSrc])


  if(isPageLoading) {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Attention"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogWording}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Paper spacing={4} style={{minHeight: '100vh',minWidth: '50vw'}}>
        <Grid container>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
          <Grid item xs={10} md={6}>
            <Box m={5}>
              <Widget disableWidgetMenu>
                <div className={classes.dashedBorder}>
                  <Typography className={classes.text} size="md">
                    Please input your name and take a photo
                  </Typography>
                </div>
              </Widget>
            </Box>
          </Grid>
          <Grid item xs={1} md={3}>
            <Paper></Paper>
          </Grid>
        </Grid>

      <Box m={4}>
        <Grid container spacing={2}>
            <Grid item xs={1} md={4}>
              <Paper />
            </Grid>
            <Grid item xs={6} md={2}>
              <Box m={1}>
                <Typography variant="h5" gutterBottom>
                Input your name here
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4} md={2}>
                <TextField
                  error={hasNameError ? true : false}
                  label="name"
                  id="outlined-error"
                  placeholder="Type your name here"
                  variant="outlined"
                  onChange={onTextFieldChange}
                />
            </Grid>
            <Grid item xs={1} md={3}>
              <Paper />
            </Grid>
          </Grid>
      </Box>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
        >
          <Camera
            buttonClick={indexClick}
            inProgress={inProgress}
            buttonText='Capture'
            height={height}
            width={width}
            />
        </Grid>
    </Paper>
    </>
  );
}


function mapStateToProps (state) {
  return {
  accessTokenState: state.AuthOptions.accessToken
}};

export default connect(mapStateToProps)(CheckInPage);